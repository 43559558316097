.btn-group {
  display: flex;

  flex-direction: column;

  margin-top: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #3e68ff;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.25em 0.75em;
  min-width: 10ch;
  min-height: 44px;
  text-align: center;
  line-height: 1.1;
  border: none;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* ----------------------------------------------
  * Generated by Animista on 2021-1-22 16:38:36
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

/**
  * ----------------------------------------
  * animation swirl-in-fwd
  * ----------------------------------------
  */
@keyframes swirl-in-fwd {
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.swirl-in-fwd {
  animation: swirl-in-fwd 0.6s ease-out both;
}

/* ----------------------------------------------
  * Generated by Animista on 2021-1-22 16:40:6
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

/**
  * ----------------------------------------
  * animation swirl-out-bck
  * ----------------------------------------
  */
@keyframes swirl-out-bck {
  0% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}

.swirl-out-bck {
  animation: swirl-out-bck 0.6s ease-in both;
}
